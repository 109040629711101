<template>
  <div class="MonitorOverview">
     <dialog
       v-show="previewDialog"
       class="image-dialog"
       ref="dialog"
     >
       <div class="image-container" >
         <h2 class="image-container_title">Save Report</h2>
         <div  class="preview-container"
               ref="custom-area"
         >
           <div
             class="preview-img"
             :style="{ backgroundColor: currentColor }"
           >
             <h2 class="branding-title">{{ formatMonitorUrl(monitor.url) }}</h2>
             <p class="branding-date">{{ previewDate }}</p>
            <main style="width: 90%">
              <div
                v-if="optionTogglers.responseTime"
                class="row mb-4"
              >
                <div class="col-md-12">
                  <widget-response-time
                    :monitor="monitor"
                    :is-open-modal="previewDialog"
                    :user="user"
                    :show-actions="false"
                    :series="series"
                  />
                </div>
              </div>

              <div
                class="statistics row flex-wrap mb-4"
                v-show="optionTogglers.shortStats"
              >
                <div class="col-md-3">
                  <average-response-time :monitor="monitor" />
                </div>
                <div class="col-md-3">
                  <uptime :monitor="monitor" :show-actions="false" />
                </div>
                <div class="col-md-3">
                  <response-time :monitor="monitor" />
                </div>
                <div class="col-md-3">
                  <ssl-certificate :monitor="monitor" />
                </div>
              </div>

              <div
                v-if="optionTogglers.timePeriods"
                class="row table"
              >
                <statistic-table :monitor="monitor"
                                 :show-actions="false"
                                  style="width: 100%"
                />
              </div>
            </main>
             <div class="branding-logo">
               <p v-if="!optionTogglers.branding" class="branding-subtitle">Powered by</p>
               <img
                 v-if="!optionTogglers.branding"
                 :src="require('@/assets/images/pulsetic-logo.svg')"
                 width="50"
                 height="15"
               /></div>
           </div>
         </div>

         <div class="bottom-actions">
           <div class="input-wrapper">
             <input
               v-model="currentColor"
               type="text"
               class="color-input"
               id="color-picker"
               maxlength="7"
             />
             <label for="color-picker">
               <div class="preview-icon" :style="{ backgroundColor: currentColor }"></div>
             </label>

             <label for="color-picker">
               <img
                 class="preview-chevron"
                 :src="require('@/assets/images/arrow_down_small.svg')"
                 alt="chevron"
                 width="12"
                 height="12"
               />
             </label>
           </div>

          <div ref="compactPicker" class="color-container">
            <chrome-picker
              :value="currentColor"
              @input="currentColor = $event.hex8"
            />
          </div>
           <div class="toggle-container">
             <base-toggle
               v-model="optionTogglers.responseTime"
               :value="optionTogglers.responseTime"
               class="even-smaller cursor-pointer"
             />
             <p class="toggle-label">Response Time</p>
             <base-toggle
               v-model="optionTogglers.shortStats"
               :value="optionTogglers.shortStats"
               class="even-smaller cursor-pointer"
               aria-label="Response time"
             />
             <p class="toggle-label">Short Stats</p>
             <base-toggle
               v-model="optionTogglers.timePeriods"
               :value="optionTogglers.timePeriods"
               class="even-smaller cursor-pointer"
             />
             <p class="toggle-label">Time periods</p>
             <div class="branding-wrapper" id="branding-label">
             <base-toggle
               v-model="optionTogglers.branding"
               :disabled="!hasPaidSubscription"
               :value="optionTogglers.branding"
               class="even-smaller cursor-pointer"
             />
             <p class="toggle-label">Remove Pulsetic Branding</p>
             </div>
           </div>
         </div>

         <div ref="branding-tooltip">
           This option is only available to our premium customers.
            <router-link :to="{ name: 'account.subscription' }" class="pricing-link">View Pricing</router-link>
         </div>

         <div class="button-container">
           <base-button
             class="button"
             @click="downloadScreenshot"
           >
             Download as Image
           </base-button>
           <base-button
             class="button"
             @click="savePdf"
           >
             Download PDF
           </base-button>
           <base-button
             class="button cancel"
             @click="onClose"
           >
             Cancel
           </base-button>
         </div>
       </div>
   </dialog>

    <div ref="preview-area">
      <div class="row mb-4" :data-html2canvas-ignore="!optionTogglers.responseTime">
        <div class="col-md-12">
          <widget-response-time
            :monitor="monitor"
            :user="user"
            :series="series"
            @open-modal="onOpen"
            @updateDateFrom="generatePreviewDate"
          />
        </div>
      </div>

      <div
        class="statistics row flex-wrap mb-4"
      >
        <div class="col-md-3">
          <average-response-time :monitor="monitor" />
        </div>
        <div class="col-md-3">
          <uptime :monitor="monitor" />
        </div>
        <div class="col-md-3">
          <response-time :monitor="monitor" />
        </div>
        <div class="col-md-3">
          <ssl-certificate :monitor="monitor" />
        </div>
      </div>

      <div class="row table">
        <div class="col-md-12 table-container">
          <statistic-table :monitor="monitor" />
        </div>
      </div>
    </div>

<!--    <div class="lighthouse">-->
<!--      <div class="lighthouse-header">-->
<!--        <div class="device">-->
<!--          <span><a href="#"-->
<!--             class="mr-2"-->
<!--             :class="{ active: lighthouseDeviceType === 'mobile' }"-->
<!--             @click.prevent="lighthouseDeviceType = 'mobile'">Mobile</a>/</span>-->
<!--          <span><a href="#"-->
<!--             class="ml-2"-->
<!--             :class="{ active: lighthouseDeviceType === 'desktop' }"-->
<!--             @click.prevent="lighthouseDeviceType = 'desktop'">Desktop</a></span>-->
<!--        </div>-->
<!--        <div class="hint">-->
<!--          <a class="hint-link" href="https://developers.google.com/web/tools/lighthouse/" target="_blank">Google Lighthouse Audit Scores ↗</a>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="lighthouse-scores">-->
<!--        <div class="score">-->
<!--          <lighthouse-score title="Performance"-->
<!--                            :score="performanceScore" />-->
<!--        </div>-->
<!--        <div class="score">-->
<!--          <lighthouse-score title="SEO"-->
<!--                            :score="seoScore" />-->
<!--        </div>-->
<!--        <div class="score">-->
<!--          <lighthouse-score title="PWA"-->
<!--                            :score="pwaScore" />-->
<!--        </div>-->
<!--        <div class="score">-->
<!--          <lighthouse-score title="Accessibility"-->
<!--                            :score="accessibilityScore" />-->
<!--        </div>-->
<!--        <div class="score">-->
<!--          <lighthouse-score title="Best Practices"-->
<!--                            :score="bestPracticesScore" />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import WidgetResponseTime from '@/components/Monitor/Widgets/ResponseTime/ResponseTime.vue'
import StatisticTable from '@/components/Monitor/Widgets/StatisticTable/StatisticTable.vue'
import Uptime from './OverviewUptime.vue'
import AverageResponseTime from './OverviewAverageResponseTime.vue'
import ResponseTime from './OverviewResponseTime.vue'
import SslCertificate from './OverviewCertificate.vue'
// import LighthouseScore from './OverviewLighthouseScore.vue'
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseToggle from '@/components/Base/BaseToggle.vue'
import JsPDF from 'jspdf'
import moment from 'moment'
import { Chrome } from 'vue-color'
import tippy from 'tippy.js'
import 'tippy.js/themes/light.css'
import monitorsApi from '@/api/monitorsApi'
import { validateIPAddress } from '@/filters/filters'
import html2canvas from 'html2canvas'

export default {
  components: {
    BaseButton,
    BaseToggle,
    Uptime,
    WidgetResponseTime,
    AverageResponseTime,
    ResponseTime,
    SslCertificate,
    StatisticTable,
    // LighthouseScore,
    'chrome-picker': Chrome
  },

  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      lighthouseDeviceType: 'mobile',
      customImage: null,
      previewDialog: false,
      currentColor: '#6252d8',
      previewDate: new Date(),
      optionTogglers: {
        responseTime: true,
        shortStats: true,
        timePeriods: true,
        branding: false
      }
    }
  },

  created () {
    if (this.user.preferences.MonitorSaveReportOptions) {
      const saveReportOptions = JSON.parse(this.user.preferences.MonitorSaveReportOptions)
      this.optionTogglers.responseTime = saveReportOptions.responseTime
      this.optionTogglers.shortStats = saveReportOptions.shortStats
      this.optionTogglers.timePeriods = saveReportOptions.timePeriods
      this.optionTogglers.branding = saveReportOptions.branding
      this.currentColor = saveReportOptions.currentColor
    }
  },
  mounted () {
    tippy('#branding-label', {
      content: this.$refs['branding-tooltip'],
      interactive: true,
      animation: 'shift-away-subtle',
      placement: 'top',
      arrow: false,
      inertia: true,
      trigger: this.hasPaidSubscription ? '' : 'mouseenter',
      maxWidth: 270
    })

    tippy('#color-picker', {
      content: this.$refs.compactPicker,
      interactive: true,
      theme: 'light',
      animation: 'shift-away-subtle',
      placement: 'bottom-end',
      arrow: true,
      inertia: true,
      trigger: 'click',
      maxWidth: 'none'
    })

    document.addEventListener('keydown', (e) => {
      if (e.code !== 'Escape') return

      this.onClose()
    })
  },
  methods: {
    async downloadScreenshot () {
      await this.getCustomScreenshot()

      const link = document.createElement('a')
      link.href = this.customImage
      link.download = 'chart-data.jpeg'
      link.click()
      link.remove()
    },
    formatMonitorUrl (url) {
      if (validateIPAddress(url)) {
        return url
      }
      // Add protocol if missing
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url
      }
      return new URL(url).hostname
    },
    generatePreviewDate (dates) {
      const format = (date) => date ? moment(date).format('LL') : moment().format('LL')
      const isSameDate = moment(dates['dateFrom']).isSame(format(dates['dateTo']))

      if (isSameDate) {
        this.previewDate = format(dates['dateFrom'])
        return
      }

      this.previewDate = `${format(dates['dateFrom'])} - ${format(dates['dateTo'])}`
    },
    onClose () {
      this.previewDialog = false
      this.$refs.dialog.close()
    },
    onOpen () {
      this.previewDialog = true
      this.$refs.dialog.showModal()
    },
    async getCustomScreenshot () {
      const element = this.$refs['custom-area']
      const canvas = await html2canvas(element, { useCORS: true })
      this.customImage = canvas.toDataURL('image/png')
    },
    async savePdf () {
      await this.getCustomScreenshot()

      const pdfDoc = new JsPDF()

      let height = 110
      let width = 200

      if (this.optionTogglers.responseTime && this.optionTogglers.shortStats && this.optionTogglers.timePeriods) height = 190
      if (!this.optionTogglers.shortStats) height = 170
      if (!this.optionTogglers.timePeriods) height = 140
      if (!this.optionTogglers.shortStats && !this.optionTogglers.timePeriods) height = 110
      if (!this.optionTogglers.shortStats && !this.optionTogglers.responseTime) height = 90
      if (!this.optionTogglers.responseTime && !this.optionTogglers.timePeriods) height = 50

      pdfDoc.addImage(this.customImage, 'JPEG', 5, 5, width, height)
      pdfDoc.save('chart-data.pdf')
    }
  },
  computed: {
    series () {
      return this.$store.getters.getSeries
    },
    performanceScore () {
      if (this.lighthouseDeviceType === 'mobile') {
        return this.monitor.mobile_performance_score
      }

      return this.monitor.desktop_performance_score
    },

    seoScore () {
      if (this.lighthouseDeviceType === 'mobile') {
        return this.monitor.mobile_seo_score
      }

      return this.monitor.desktop_seo_score
    },

    pwaScore () {
      if (this.lighthouseDeviceType === 'mobile') {
        return this.monitor.mobile_pwa_score
      }

      return this.monitor.desktop_pwa_score
    },

    accessibilityScore () {
      if (this.lighthouseDeviceType === 'mobile') {
        return this.monitor.mobile_accessibility_score
      }

      return this.monitor.desktop_accessibility_score
    },

    bestPracticesScore () {
      if (this.lighthouseDeviceType === 'mobile') {
        return this.monitor.mobile_best_practices_score
      }

      return this.monitor.desktop_best_practices_score
    },
    hasPaidSubscription () {
      return this.user.teamowner_plan && this.user.teamowner_plan.name !== 'Free'
    },

    ...mapState('authentication', [
      'user'
    ])
  },

  watch: {
    optionTogglers: {
      handler (values) {
        values.currentColor = this.currentColor
        monitorsApi.storeMonitorSaveReportOptions(values)
      },
      deep: true
    },

    currentColor (newValue) {
      const values = this.optionTogglers
      values.currentColor = newValue
      monitorsApi.storeMonitorSaveReportOptions(values)
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorOverview {
    .pricing-link {
      color: white;
      text-decoration: underline;
    }

    .preview-icon {
      position: absolute;
      top: 14px;
      left: 35px;
      height: 15px;
      width: 15px;
      border-radius: 3px;
    }

    .preview-chevron {
      position: absolute;
      top: 16px;
      right: 20px;
    }

    .color-input {
      height: 44px !important;
      width: 160px !important;
      margin: 0 5px 0 15px;
      border: 1px solid #F6F8F9;
      padding-left: 40px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(25,34,29,.05);
    }

    .color-container {
      .vc-chrome {
        box-shadow: none !important;
      }
    }

    .preview-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-radius: 7px;
      width: 98%;
      padding: 0;
      background-color: #FFFFFF;
    }

    .preview-img {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-radius: 7px;
      width: 100%;
      padding: 17px 0;

      .branding-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.022em;
        text-align: left;
        color: white;
      }

      .branding-date {
        font-size: 11px;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: -0.022em;
        width: 225px;
        text-align: center;
        color: white;
        margin-bottom: 30px;
      }

      .branding-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin: -15px 0 30px 0;
        width: 250px;
      }

      .branding-subtitle {
        font-size: 9px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0.028em;
        text-align: right;
        text-transform: uppercase;
        color: white;
        width: 100px;
        margin: 4px 4px 0 0;
      }
    }

    .image-container {
      width: 1200px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &_title {
        align-self: flex-start;
        margin-left: 15px;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.019em;
        text-align: left;
      }
    }

    .image-dialog {
      border: none;
      border-radius: 3px;
      background-color: #F6F8F9;
      max-height: unset;
      overflow: visible;
      position: absolute;
      top: 35%;

      @media (max-height: 830px) {
        top: 100%;
      }

      @media (min-height: 830px) and (max-height: 1100px) {
        top: 70%;
      }

      &::backdrop {
        background-color: rgba(0, 0, 0, 0.7);
      }

      .bottom-actions {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 30px 0;

        .toggle-container {
          display: flex;
          margin-left: 15px;
          align-items: center;
          justify-content: space-evenly;
          width: 65%;

          .branding-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }

        .input-wrapper {
          position: relative;
        }

        .BaseToggle {
          &.toggle--size-small.even-smaller {
            width: 25px !important;
          }
        }

        .even-smaller::v-deep {
          margin: 0 5px 0 0;

          input[type="checkbox"] {
            ~ label .toggle__switch {
              height: 12px !important;
              max-width: 20px !important;

              &:after {
                height: 8px;
                width: 8px;
                position: absolute;
                top: 50%;
                left: 3px;
                transform: translateY(-50%);
              }
            }

            &[disabled] ~ label {
              color: rgba(#777, 0.5);
            }
            &:focus ~ label, &:hover ~ label {
              .toggle__switch {
                background-color: #777;
                &:after {
                  color: darken(#777, 10%);
                }
              }
            }
            &:hover ~label { color: darken(#777, 5%); }
            &:checked {
              ~ label {
                &:hover { color: darken(map-get($colors, purple-1), 3%); }

                .toggle__switch {
                  background-color: map-get($colors, purple-1);
                  &:after {
                    color: darken(map-get($colors, purple-1), 5%);
                    height: 8px;
                    width: 8px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(10px, -50%);
                    transition: 0.3s;
                  }
                }
              }

              &:focus, &:hover {
                ~ label {
                  .toggle__switch {
                    background-color: map-get($colors, purple-1);
                    &:after {
                      color: darken(map-get($colors, purple-1), 10%);
                    }
                  }
                }
              }
            }
          }

          label {
            .toggle__switch {
              transition: background-color 0.3s cubic-bezier(0,1,0.5,1);
              background: lighten(#777, 5%);
              font-weight: 600;
              &:before { color: rgba(white, 0.5) }
              &:after {
                transition: transform .25s,width .25s;
                transition-timing-function: cubic-bezier(.23,2.01,.43,.66);
                color: #777;
              }
            }
          }
        }

        .toggle-label {
          font-size: 16px;
          font-weight: 500;
          line-height: 1;
          margin: 0 20px 0 0;

          &:last-child {
            margin-left: 10px;
          }
        }
      }

      .button-container {
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        margin: 0 0 20px 15px;

        .button {
          margin-right: 14px;
          background-color: #27AE60;
        }
        .cancel {
          background-color: transparent;
          color: #4F4F4F;
          border: 1px solid #D2D2D2;
          border-radius: 6px;
        }
        .download {
          color: white;
        }
      }
    }
    .lighthouse-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .device {
        text-decoration: none;

        a {
          font-weight: 500;
        }
      }

      .device .active {
        color: map-get($colors, purple-1);
        text-decoration: none;
        pointer-events: none;
        cursor: default;
      }

      .device a {
        text-decoration: none;
        text-decoration: transparent;
      }
      .hint{
        padding-right: 6px;
        &-link {
          font-size: 14px !important;
          font-weight: 400 !important;
          letter-spacing: -0.019em;
          text-decoration: underline;
          color: #BDBDBD !important;
        }
      }
    }

    .table {
      margin: 0 0 35px 0;
      padding: 0;

      &-container {
        padding: 0;
      }
    }

    .lighthouse-scores {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .score {
        flex: 1 0;

        ::v-deep {
          .card-body {
            box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
          }
          .card-time {
            font-size: 42px;
            font-weight: 600;
            line-height: 100%;
            letter-spacing: -0.019em;
            color: #333333;
          }
          .card-score-max {
            font-size: 14px;
            font-weight: 500;
            color: #BDBDBD;
          }
        }

        &:not(:first-child) {
          margin-left: 20px;
        }
      }
    }

    .statistics {
      ::v-deep {
        .card-body {
          box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
        }
        .heading {
          font-size: 15px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.019em;
          color: #828282;
        }
        .card-time {
          font-size: 48px;
          font-weight: 600;
          line-height: 100%;
          letter-spacing: -0.019em;
          color: #333333;
        }
        .card-time-unit {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.019em;
          color: #4F4F4F;

          &-status{
            color: #BDBDBD;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: -0.003em;
          }
        }
        .no-data {
          font-size: 13px;
          font-weight: 400;
          color: #BDBDBD;
          letter-spacing: -0.003em;
        }
      }
    }
  }
</style>
